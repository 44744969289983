
/* //message */
.block__message{
  min-height: 100px;
  background-color: #1b1e21;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 25px 20px;
  color: #e2e2e2;
}
/* header */
.img__user{
  width: 30px;
  border-radius: 50px;
  margin-right: 2px;
}
.img__added{
  max-width: 50px;
  border-radius: 4px;
}
.description__block{
  margin-top: 10px;
}
/* answer */
.block__answer{
  margin-left: 4rem;
  margin-bottom: 30px;
}
.div__answer{
  min-height: 100px;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 25px 20px;
  color: #fff;
  background-color:#1b1e21a8;
}
.button__index{
  z-index: 10;
}
