body, html {
    margin: 0;
    background-color: #222529;
    color: #e2e2e2;
}
.container{
  max-width: 900px;
}
.form-control {
  background-color: #2a2d32 !important;
  border: #222529 !important;
  color: #f3f1f1 !important;
}
.uploadremove {
  background: #dc3545;
  border: 0;
  border-radius: 50%;
  height: 20px;
  line-height: 20px;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: all .3s ease-in-out;
  width: 20px;
  z-index: 1;
}
.error{
  color: #dc3545 !important;
    font-size: .875em;
    margin-top: 0.25rem;
    width: 100%;
}
.z__index__1200 {
  z-index: 200!important;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #767c846c !important;
}